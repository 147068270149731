.position-relative {
  position: relative;
}

.font-sm,
.MuiFormLabel-root,
.MuiInputBase-input,
.font-sm .MuiChip-label,
.font-sm .MuiButton-root,
.font-sm .MuiMenuItem-root,
.font-sm .MuiTableCell-root,
.font-sm .MuiTypography-body2,
.font-sm .MuiButton-textSizeSmall,
.MuiPopover-root .MuiPaper-root .MuiMenuItem-root,
.font-sm+.MuiTablePagination-root .MuiTypography-body2,
.font-sm+.MuiTablePagination-root .MuiButton-textSizeSmall {
  font-size: 11.5px !important;
}

.MuiFormHelperText-root {
  font-size: 10px !important;
}

.MuiFilledInput-inputMarginDense {
  padding-top: 18px !important;
  padding-bottom: 4px !important;
}

.MuiInputLabel-formControl {
  top: -3px !important;
}

.MuiSelect-filled.MuiSelect-filled {
  padding-top: 11px !important;
}

.MuiPaper-root.select-location .MuiListItem-root {
  padding-top: 2px;
  padding-bottom: 3px;
}

.narrow-cell .MuiTableCell-sizeSmall {
  padding-top: 0px;
  padding-bottom: 0px;
}

.narrow-cell .MuiButton-textSizeSmall {
  padding: 0px 5px;
  margin: 4px auto;
}

.box {
  box-sizing: border-box;
}

.text-error {
  color: #722F37;
}

#main-content {
  padding-left: 25px;
  padding-right: 25px;
}

.layout.partner #main-content {
  padding: 0 !important;
  margin: 0 !important;
}

.layout.partner header>div>.MuiButtonBase-root {
  display: none;
}

.MuiTypography-body1.app-title {
  font-size: 1.25em;
  color: #fff;
}

.layout>div>main>div>.MuiDrawer-paperAnchorLeft {
  width: 55px;
  background-color: #fff;
}
@media (min-width: 0px) and (max-width: 599.95px)
{
  .jss40, .MuiDrawer-paperAnchorLeft{
    background-color: #fff !important;
  }
  .MuiDrawer-paperAnchorLeft .MuiBox-root{
    color: #000 !important;
  }
} 
.layout.expand>div>main>div>.MuiDrawer-paperAnchorLeft {
  width: 220px;
  position: fixed;
  padding-left: 20px;
  padding-right: 20px;
}

.layout>div>main>div>.MuiDrawer-paperAnchorLeft .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
  font-size: 1.8rem;
}

.layout>div>main>div>.MuiDrawer-paperAnchorLeft a,
.layout>div>main>div>.MuiDrawer-paperAnchorLeft li {
  margin-bottom: 0.5em;
  font-size: 0.875rem;
}

.layout>div>main>div>.MuiDrawer-paperAnchorLeft li {
  display: flex;
}

.layout>div>main>div>.MuiDrawer-paperAnchorLeft li>.MuiListItemIcon-root:last-of-type {
  justify-content: flex-end;
  flex-grow: 1;
}

.layout>div>main>div>.MuiDrawer-paperAnchorLeft>div>a.MuiListItem-root {
  font-weight: 600;
  border-radius: 4px;
  color: #722F37;;
}

.layout>div>main>div>.MuiDrawer-paperAnchorLeft>div>a:hover.MuiListItem-root,
.layout>div>main>div>.MuiDrawer-paperAnchorLeft>div>a:active.MuiListItem-root,
.layout>div>main>div>.MuiDrawer-paperAnchorLeft>div>a:focus.MuiListItem-root {
  color: #3f51b5;
}

.layout.expand #main-content {
  margin-left: 260px;
}

.btn-loading {
  top: 50%;
  left: 50%;
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  margin-top: -12px;
  margin-left: -12px;
}

.MuiCircularProgress-colorPrimary {
  color: #1a2038 !important;
}

.MuiCircularProgress-circle {
  stroke-width: 3.6;
}

.signup {
  background-size: cover;
}

@media (min-width: 450px) {
  .signup {
    background: no-repeat center url('../../assets/akb_des.jpg'), #1A2038;
  }
}

@media (max-width: 449.99px) {
  .signup {
    background: no-repeat center url('../../assets/akb_mob.jpg'), #1A2038;
  }
}

.signup {
  background: no-repeat center url('../../assets/akb_des.jpg'), #1A2038;
  background-size: cover;
}

@media (max-width: 599px) {
  .signup .signup-card {
    min-width: 100px !important;
  }

  .signup .signup-card img {
    margin-bottom: 8% !important;
  }
}

.signup .signup-card {
  max-width: 800px;
  min-width: 600px;
  border-radius: 12px !important;
}

.signup .signup-card img {
  width: 200px;
  max-width: 96%;
  margin-bottom: 36%;
}

.signup .signup-card form {
  margin-top: -0.12em !important;
}

.signup .signup-card .product-title {
  margin-bottom: 0px;
  border: solid rgba(223, 31, 38, 0.04) 1px;
  padding: 8px 12px;
  border-radius: 4px;
  background: rgba(223, 31, 38, 0.04);
}

.signup .signup-card .MuiFormLabel-root {
  color: rgba(52, 49, 76, 0.54);
  font-size: 13.85px;
}

.signup .signup-card .MuiInputBase-root {
  color: rgba(52, 49, 76, 1);
  box-sizing: border-box;
  line-height: 1.1876em;
  align-items: center;
  font-size: 14px;
}

.signup .signup-card .MuiSvgIcon-root {
  margin-bottom: -2.5px;
  height: 15px;
  width: 15px;
}

.signup .signup-card .MuiButton-root {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
}

.signup .signup-card .MuiFormHelperText-root.Mui-error {
  display: none;
}

.signup .signup-card .MuiInput-underline.Mui-error:after {
  border-bottom-color: transparent;
}

.signup .signup-card .MuiInput-underline.Mui-focused:after {
  border-bottom-color: #3f51b5 !important;
}

.signup .signup-card .MuiButton-contained {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%);
}

.signup .signup-card .MuiButton-containedPrimary{
  background-color: #722F37;
}

.signup .signup-card .MuiButton-containedPrimary:hover {
  background-color: rgb(44, 56, 126);
}

.signup .signup-card .small-text {
  font-size: 11px;
}

.MuiDialog-root .MuiPaper-elevation24 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%);
}

.AkAction--popup .MuiMenu-list>.MuiMenuItem-root:not(:first-child) {
  border-top: 1px solid #F2F2F2;
}

.AkAction--popup .MuiList-padding {
  padding-top: 4px;
  padding-bottom: 4px;
}

.bg-light-gray {
  background: rgba(0, 0, 0, 0.08) !important;
}

.h-full {
  height: 100% !important;
}

.h-full-screen {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.w-full-screen {
  width: 100vw;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.y-up-center {
  top: 50%;
  transform: translateY(-50%);
}

.p-0 {
  padding: 0 !important;
}

.p-8 {
  padding: 2em !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5em !important;
}

.pb-1 {
  padding-bottom: 1em !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}