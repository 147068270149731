
/* Dialogs
   ========================================================================== */

   .MuiDialog-root:not(.full-width) .MuiDialog-paperWidthSm {
    max-width: 850px !important;
  }
  
  @media (min-width: 960px) { 
    .AkDialog-root .MuiDialog-paperWidthSm {
      width: 680px;
    }
  }
  
  @media (min-width: 426px) {
    .AkDialog-root.season-data .MuiPaper-root {
      width: 325px;
    }
  }
  
  @media (max-width: 425.98px) { 
    .AkDialog-root.season-data .MuiPaper-root {
      width: 90%;
    }
  }
  
  .MuiDialog-root.my-profile {
    margin-top: 6.5em;
  }
  
  .MuiDialog-root.aggregated-requests {
    margin-top: 3.5em;
  }
  
  .MuiDialog-root.my-profile   {
    margin-right: 24px;
    -webkit-transition: linear 195ms;
    transition: linear 195ms;
  }
  
  .MuiDialog-root.my-profile.expand,
  .MuiDialog-root.aggregated-requests.expand {
    margin-left: 285px;
  }
  
  .MuiDialog-root.my-profile,
  .MuiDialog-root.aggregated-requests {
    margin-left: 60px;
  }
  
  .MuiDialog-root.aggregated-requests>.MuiDialog-container>.MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }
  
  .MuiDialog-root.aggregated-requests .list-page>.MuiToolbar-root,
  .MuiDialog-root.aggregated-requests .list-page .MuiPaper-root {  
    margin-right: 25.85px; /* includes 24px standard */
  }
  
  .MuiDialog-root.aggregated-requests .list-page .MuiPaper-root {
    margin-left: 1.85px;
  }
  
  .MuiDialog-root.aggregated-requests .list-page .MuiPaper-root .MuiTablePagination-root .MuiToolbar-root {
    justify-content: flex-end;
    padding-right: 25px;
  }
  
  @media (max-width: 599.98px) {
    .MuiDialog-root.my-profile {
      margin-top: 5.25em;
    }
  
    .MuiDialog-root.my-profile,
    .MuiDialog-root.my-profile.expand,
    .MuiDialog-root.aggregated-requests,
    .MuiDialog-root.aggregated-requests.expand {
      margin-left: 24px;
    }  
  }
  
  .MuiFormControl-root .previews {
    display: flex;
    justify-content: center;
  }
  
  .MuiDialog-root.my-profile .profile-img--small,
  .MuiFormControl-root .previews img,
  .AkRegisterForm-body .previews {
    border-radius: 50%;
    height: 150px;
    width: 150px;
  }
  
  .MuiDialog-root.my-profile .profile-img--small,
  .MuiFormControl-root .previews img {
    object-fit: cover;
  }
  
  .AkRegisterForm-body .previews.placeholder {
    background-color: #e8e8e8;
    margin: 10px 0 0 15px;
  }
  
  .AkRegisterForm-body .previews.image {
    background-position: center center;
    background-size: cover;
  }
  
  .new-photo .previews {
    margin-left: 25px;
  }
  
  div.image {
    animation-delay: 1s;
  }
  
  .MuiDialog-root.my-profile .password-section {
    transition: opacity 0.5s linear;
  }
  
  .MuiDialog-root.my-profile .password-section,
  .MuiDialog-root.my-profile .detail-section,
  .aside-section .MuiTypography-root {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  
  .MuiDialog-root.my-profile .password-section p > span,
  .MuiDialog-root.my-profile .detail-section p > span {
    font-weight: 600;
  }
  
  .MuiDialog-root.my-profile .MuiPaper-root {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: 5px solid #fff;
  }
  
  @media (max-width: 599.98px) {
    .MuiDialog-root.my-profile .MuiPaper-root {
      border-top-left-radius: 21.5px;
      border-top-right-radius: 21.5px;
    }
  }
  
  .MuiDialog-root.my-profile .edit-page > div {
    margin-top: 0;  
  }
  
  .MuiDialog-root.my-profile .edit-page .MuiPaper-root {
    box-shadow: none;  
  }
  
  .layout.my-profile .general-sidemenu,
  .layout.farmer-info .general-sidemenu,
  .layout:not(.my-profile) .my-profile-sidemenu,
  .MuiDrawer-root.my-profile .general-sidemenu,
  .MuiDrawer-root.farmer-info .general-sidemenu,
  .MuiDrawer-root:not(.my-profile) .my-profile-sidemenu,
  .MuiDialog-root.my-profile .MuiBackdrop-root,
  .MuiDialog-root.aggregated-requests .MuiBackdrop-root,
  .funders--modal .simple-form.register-form > .MuiToolbar-root > .save-and-exit-button {
    display: none !important;
  }
  
  .layout.my-profile main > #main-content {
    padding: 0 !important;
  }
  
  .layout.my-profile .MuiListItem-button,
  .MuiDrawer-root.my-profile .MuiListItem-button {
    border-bottom: 1px solid rgba(0, 0, 0, 0.008);
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; 
  }
  
  .layout.my-profile .MuiListItem-button:hover,
  .layout.my-profile .MuiListItem-button:focus,
  .MuiDrawer-root.my-profile .MuiListItem-button:hover,
  .MuiDrawer-root.my-profile .MuiListItem-button:focus {
    background-color: rgba(0, 0, 0, 0.065);
  }
  
  .layout.my-profile .MuiListItem-button:active,
  .MuiDrawer-root.my-profile .MuiListItem-button:active,
  .my-profile-sidemenu a.activated {
    font-weight: 600;
    background-color: #ff9e43 !important;
  }
  
  .MuiDialog-root.my-profile .MuiButton-root > span > span {
    padding-left: 0 !important;
  }
  
  .MuiDialog-root.my-profile .export-profile {
    text-decoration: none;
  }
  
  .MuiDialog-root.my-profile .change-password > .MuiButton-root:last-child {
    margin-right: 1.125em;
  }
  
  .MuiDialog-root.my-profile .change-password > .MuiButton-root.small--btn {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  
  .MuiDialog-root.my-profile .change-password > .MuiButton-containedPrimary {
    background-color: #3f51b5 !important;
  }
  
  @media (min-width: 960px) { 
    .MuiPaper-root .aside-section {
      margin: 0 1.85em 0 1.25em;
    }
    .MuiPaper-root .detail-section > .MuiBox-root > div {
      margin-right: 1.85em;
    }
    .MuiPaper-root .start .MuiButton-root .MuiButton-label > span,
    .MuiPaper-root .aside-section .MuiButton-root .MuiButton-label > span,
    .MuiPaper-root .detail-section .MuiButton-root .MuiButton-label > span {
      padding-right: 0.5em;
    }
    .MuiDialog-root.my-profile .password-section > .MuiBox-root:first-child {
      display: flex;
      justify-content: center;
      margin-top: -0.9em;
    }
    .MuiDialog-root.my-profile .detail-section {
      margin-right: 2.5em;
    }
  }
  
  @media (max-width: 320.98px) {
    .add-service--modal .MuiDialogContent-dividers {
      padding: 16px 4px;
    }
  }
  
  .provider-requests--modal .MuiDialog-paper, .provider-requests--modal .MuiDialog-paper .MuiCard-root {
    color: #0000009f;
  }
  
  .provider-requests--modal .MuiPaper-root .MuiTypography-h6, .requested-by-title .requester-names, .requested-by-title .requester-location {
    font-size: 1.2rem;
  }
  
  .provider-requests--modal .MuiPaper-root .details {
    margin: 0 auto;
  }
  
  .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:first-child, .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root>td:first-child {
    display: table-cell;
  }
  
  .provider-requests--modal .accept.confirm--delivery {
    float: right;
  }
  
  .add-service--modal .MuiToolbar-root>.MuiButton-containedPrimary:not(:first-child) {
    background-color: #3f51b5 !important;
  }
  
  .request--modal .MuiDialog-paper,
  .funders--modal .MuiDialog-paper {
    width: 320px;
  }
  
  .funders--modal .MuiFormControl-root>div,
  .funders--modal .ra-input .MuiFormControl-root,
  .funders--modal .MuiFormControl-root[class*='input']>div {
    width: 100% !important;
  }
  
  .funders--modal .MuiDialog-paper .MuiPaper-root {
    margin-top: -1em;
  }
  
  .funders--modal .simple-form .MuiToolbar-root {
    justify-content: center !important;
  }
  
  .request--modal .MuiPaper-root .MuiCardContent-root > .ra-input {
    display: flex !important;
    flex-direction: column;
  }
  
  .request--modal .MuiDialog-paper .MuiDialogTitle-root {
    padding: 16px 24px 0px;
  }
  
  .request--modal .MuiDialog-paper .MuiDialogContent-root {
    padding: 0px 24px 12px;
  }
  
  .request--modal .simple-form.request {
    min-height: 50px;
  }
  
  .request--modal .simple-form.request > .MuiCardContent-root:first-child {
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-bottom: 0px;
  }
  
  .request--modal .simple-form.request .MuiToolbar-root {
    margin-top: 38px;
  }
  
  .message--modal .MuiDialog-paper .MuiDialogContent-root {
    padding: 0px 24px 24px;
  }
  
  @media (max-width: 599.98px) {
    .provider-requests--modal .MuiPaper-root .MuiTypography-h6, .requested-by-title .requester-names, .requested-by-title .requester-location {
      font-size: 1.09375rem;
    }
    .provider-requests--modal .MuiDialogTitle-root {
      padding: 16px;
    }
    .provider-requests--modal .MuiDialogTitle-root .MuiIconButton-root {
      padding: 8px;
    }
    .provider-requests--modal .MuiTable-root .MuiTableRow-root .MuiTableCell-root:first-child {
      padding-left: 6.5px;
    }
    .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>th:last-child, .provider-requests--modal .MuiTable-root .MuiTableRow-root .MuiTableCell-root:last-child {
      padding-right: 6.5px;
    }
    .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableHead-root>.MuiTableRow-root>.MuiTableCell-root:not(:last-child), .provider-requests--modal .MuiPaper-root .MuiTable-root>.MuiTableBody-root>.MuiTableRow-root:not(:last-child)>.MuiTableCell-root:not(:last-child) {
      text-align: left;
    }
    .provider-requests--modal .MuiPaper-root .MuiTable-root .MuiChip-label {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  
  .profile--modal .MuiToolbar-root>.MuiButton-containedPrimary:not(:first-child),
  .add-crop--modal .MuiToolbar-root>.MuiButton-containedPrimary:not(:first-child) {
    background-color: #722F37 !important;
  }
  
  @media (max-width: 375.98px) {
    .profile--modal .MuiDialogContent-dividers,
    .add-crop--modal .MuiDialogContent-dividers {
      padding: 16px 10px;
    }
  }
  
  @media (max-width: 320.98px) {
    .profile--modal .MuiDialogContent-dividers,
    .add-crop--modal .MuiDialogContent-dividers {
      padding: 16px 4px;
    }
  }
  
  .add-service--modal .MuiToolbar-root>.MuiButton-containedPrimary:not(:first-child) {
    background-color: #3f51b5 !important;
  }
  
  @media (max-width: 375.98px) {
    .add-service--modal .MuiDialogContent-dividers {
      padding: 16px 10px;
    }
  }
  
  .service-template--modal .service-buttons {
    padding: 12.5px 10px;
  }
  .service-template--modal .selected-file {
    padding: 0 20px;
  }
  .service-template--modal .selected-file-title {
    display: block;
    margin-bottom: 6px;
  }
  .service-template--modal .MuiDialogActions-root {
    padding: 8px 32.5px;
  }
  .service-template--modal .MuiDialogActions-root .MuiButton-containedPrimary {
    background-color: #3f51b5 !important;
  }
  .service-template--modal .MuiDialogActions-root .MuiCircularProgress-colorPrimary {
    color: #fafafa !important;
  }
  
  @media (min-width: 960px) {
    .service-template--modal .service-buttons > .MuiButton-root:nth-child(2) {
      margin-left: 85px;
    }
  }
  
  @media (max-width: 959.98px) {
    .service-template--modal .MuiDialogTitle-root > .MuiTypography-root {
      margin-right: 42px;
    }
    .service-template--modal .service-buttons {
      flex-direction: column;
    }
    .service-template--modal .service-buttons > .MuiButton-root:nth-child(2) {
      margin-top: 17.5px;
    }
  }
  
  
  /* Fields
     ========================================================================== */
  
  .AkDialog-root .MuiPaper-root .MuiCardContent-root > .ra-input {
    display: inline-block !important;
  }
  
  .AkDialog-root.season-data .MuiPaper-root .MuiCardContent-root > .ra-input {
    display: flex !important;
    justify-content: center;
  }
  
  .AkDialog-root.crops--modal .MuiPaper-root .MuiCardContent-root > .ra-input {
    display: flex !important;
    flex-direction: column;
  }
  
  @media (max-width: 700px) {
    .AkDialog-root .MuiPaper-root .MuiCardContent-root > .ra-input {
      display: block !important;
    }
  }
  
  .simple-form.profile .MuiFormControl-root {
    width: 256px;
  }
  
  .simple-form.profile .MuiFormControl-root .MuiChip-root {
    border: 2px solid #3f51b5;
  }
  
  .simple-form.profile .MuiFormControl-root .MuiFilledInput-underline:after {
    border-bottom-color: #3f51b5 !important;
  }
  
  .simple-form.profile .MuiFormControl-root[class*='input'].edit, .simple-form.profile .MuiFormControl-root.edit {
    margin-top: 0 !important;
  }
  
  @media (min-width: 1260px) {
    .simple-form.profile .MuiFormControl-root[class*='input'], .simple-form.profile .MuiFormControl-root {
      margin: 0.5em 0 1.125em 0 !important;
    }  
  }
  
  .simple-form.profile.message > .MuiCardContent-root {
    display: flex;
    flex-direction: column;
  }
  
  .simple-form.profile:not(.message):not(.crops) .ra-input:nth-child(odd) .MuiFormControl-root[class*='input'], .simple-form.profile:not(.message):not(.crops) .ra-input:nth-child(odd) .MuiFormControl-root,
  .simple-form.profile.provider .ra-input .address > .MuiFormControl-root[class*='input']:nth-child(odd), .simple-form.profile.provider .ra-input .address > .MuiFormControl-root:nth-child(odd),
  .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root[class*='input'], .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root,
  .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root[class*='input']:nth-child(even), .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root:nth-child(even) {
    margin-right: 3em !important;
  }
  
  @media (max-width: 735.98px) {
    .simple-form.profile.provider .ra-input .address > .MuiFormControl-root[class*='input']:nth-child(odd), .simple-form.profile.provider .ra-input .address > .MuiFormControl-root:nth-child(odd),
    .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root[class*='input'], .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root,
    .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root[class*='input']:nth-child(even), .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root:nth-child(even) {
      margin-right: 2em !important;
    }
  }
  
  @media (max-width: 719.98px) {  
    .simple-form.profile.provider .ra-input .address > .MuiFormControl-root[class*='input']:nth-child(odd), .simple-form.profile.provider .ra-input .address > .MuiFormControl-root:nth-child(odd),
    .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root[class*='input'], .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root,
    .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root[class*='input']:nth-child(even), .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root:nth-child(even) {
      margin-left: 2em !important;
    }
    .simple-form.profile.provider > .MuiCardContent-root {
      text-align: center;
    }
  }
  
  @media (max-width: 479.98px) {  
    .simple-form.profile.provider .ra-input .address > .MuiFormControl-root[class*='input']:nth-child(odd), .simple-form.profile.provider .ra-input .address > .MuiFormControl-root:nth-child(odd),
    .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root[class*='input'], .simple-form.profile.provider .address.search > div >div:first-child .MuiFormControl-root,
    .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root[class*='input']:nth-child(even), .simple-form.profile.provider .ra-input .address.results .MuiFormControl-root:nth-child(even) {
      margin-left: 0em !important;
      margin-right: 0em !important;
    }
  }
  
  .simple-form.profile .ra-input .MuiFormLabel-root,
  .simple-form.seasonal-form .ra-input .MuiFormLabel-root {
    color: #3f51b5 !important;
  }
  
  .simple-form.profile .MuiFormControl-root[class*='input'].edit >div>div, .simple-form.profile .MuiFormControl-root.edit >div>div {
    padding: 6px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  @media (min-width: 960px) {
    .simple-form.profile {
      min-height: 350px;
    }
  }
  
  @media (max-width: 959.99px) {
    .simple-form.profile .ra-input .MuiFormControl-root.edit {
      padding: 0 !important;
    }
  }
  
  @media (min-width: 699.98px) and (max-width: 750px) {
    .simple-form.profile .ra-input:nth-child(odd) .MuiFormControl-root[class*='input'], .simple-form.profile .ra-input:nth-child(odd) .MuiFormControl-root {
      margin-right: 0.75em !important;
    }
  }
  
  @media (max-width: 700px) {
    .simple-form.profile .ra-input:nth-child(odd) .MuiFormControl-root[class*='input'], .simple-form.profile .ra-input:nth-child(odd) .MuiFormControl-root {
      margin-right: 0 !important;
    }
  }
  
  @media (min-width: 960px) {
    .simple-form.profile {
      min-height: 0;
    }
    .simple-form .MuiToolbar-root {
      margin-top: 0;
    }
  }
  
  @media (min-width: 599.98px) and (max-width: 959.98px) {
    .small--btn.MuiButton-root > .MuiButton-label > span:first-of-type {
      display: none;
    }
  }
  
  @media (max-width: 599.98px) {
    .simple-form.basic-form .aside-section .small--btn.MuiIconButton-root,
    .simple-form.basic-form .detail-section .small--btn.MuiIconButton-root {
      box-shadow: none;
    }
    .simple-form.profile > .MuiCardContent-root:first-child {
      padding: 16px !important;
    }
  }
  
  @media (max-width: 425.98px) {
    .simple-form.profile > .MuiCardContent-root:first-child {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
  
  @media (max-width: 320.98px) {
    .simple-form.profile .MuiFormControl-root {
      width: 216px;
    }
  }
  
  .simple-form.message .MuiFilledInput-multiline.MuiFilledInput-marginDense {
    min-height: 120px;
    align-items: flex-start;
  }
  
  .MuiDialog-root .message-box {
    padding: 2px 15px;
    background-color: rgba(63, 81, 181, 0.08);
    border-radius: 5px;
  }
  
  .MuiDialog-root .after-message-box > p {
    font-size: 11.5px;
    text-align: right;
    margin-top: 0 !important;
    color: rgba(0, 0, 0, 0.35);
  }
  
  .MuiDialog-root .message-box .message-content > p {
    line-height: 1.5;
  }
  
  
  